// 路由配置
import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// 前台根视图
import Index from './containers/Index.vue'

const router = new Router({
	mode: 'history',
	routes: [
		{
			// 主页
			path: '/',
			name: 'index',
			component: Index,
		},
	]
})

export default router
