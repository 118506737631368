<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="20" :offset="4">
        <el-form ref="form" label-width="98px">
          <el-form-item label="充值游戏:" >
            <el-col :span="6">
              <label class="fl">{{ game_name }}</label>
            </el-col>
            <el-col :span="6">
              <label class="fl">{{ server_name }}</label>
            </el-col>
            <el-col :span="12">
              <label class="fl">{{ player_name }}</label>
            </el-col>
          </el-form-item>
          <el-form-item label="充值金额:">
            <p class="bbl">{{ charge_value }}元</p>
          </el-form-item>
          <el-form-item label="充值方式:">
            <el-radio-group v-model="pay_way" @change="pay_way_change">
              <el-radio-button label="支付宝"></el-radio-button>
              <el-radio-button label="微信"></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item>
            <el-col :span="12">
              <el-button type="primary" :style="{ width: '153px' }" @click="charge()">
                立即充值
              </el-button>
            </el-col>
          </el-form-item> -->
          <el-form-item v-loading="loading" :style="{'width': '350px','height':'153px'}">
            <vue-qr :correctLevel="3" :autoColor="false" colorDark="#313a90" :bgSrc="bg_src" :logoSrc="logo_src" :text="charge_url" :size="153" :margin="0" :logoMargin="3"></vue-qr>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import {dbOperation} from '../store/request';
export default {
  name: "Index",
  methods:{
    getRouterData() {
      this.query = this.$route.query;
      if(this.query.game_name){
        this.game_name = this.query.game_name;
      }
      if(this.query.server_name){
        this.server_name = this.query.server_name;
      }
      if(this.query.role_name){
        this.player_name = this.query.role_name;
      }
      if(this.query.price){
        this.charge_value = this.query.price;
      }
      this.query.pay_code = "Alipay";
      this.loading = true;
      dbOperation('get_qrcode',this.query).then(res => {
        if (res.qrcode) {
          this.loading = false;
          this.charge_url = res.qrcode;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
      console.log(this.query);
    },
    pay_way_change(){
      console.log("支付方式发生改变")
      // this.charge_url = "";
      if(this.pay_way == "支付宝"){
        this.query.pay_code = "Alipay";
      }else if(this.pay_way == "微信"){
        this.query.pay_code = "Heewxpay";
      }
      this.loading = true;
      dbOperation('get_qrcode',this.query).then(res => {
        if (res.qrcode) {
          this.loading = false;
          this.charge_url = res.qrcode;
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      });
    },
  },
  data() {
    return {
      query: {}, //这里是url查询参数 
      game_name: "传奇霸业",
      server_name: "一区",
      player_name: "土豪",
      charge_value: 10,
      pay_way: "支付宝",

      bg_src: "",
      logo_src: "",
      charge_url: "",
      
      loading: false
    };
  },
  created() {
    this.getRouterData();
    //这里可能还需要调用 server端获取角色信息
    //然后生成 charge_url
  },
  components: {
    VueQr
  }
}
</script>

<style>
p.bbl {
  border-style: solid green;
  font-size: 18px;
}
label.fl {
  font-size: 18px;
}
.el-row {
  padding-top: 20px;
  margin-bottom: 20px;
}
.el-form-item__label {
  font-size: 18px;
}
</style>