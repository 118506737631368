import fetch from 'isomorphic-fetch'

export function dbOperation(operation, data) {
	console.log(data);

	return new Promise((resolve, reject) => {
		fetch(`/${operation}`, {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'text/json'
			},
			body: JSON.stringify(data)
		})
			.then(res => {
				return res.json()
			})
			.then(json => {
				resolve(json)
			})
			.catch(err => {
				reject(err)
			})
	})
}

